<template>
    <layout-reconciliation-start
        :actionList="actionList"
        modePage="history" 
    />
</template>

<script>
/** Страница историй импорта */
import LayoutReconciliationStart from '@/layouts/LayoutReconciliationStart';
export default {
    name: 'PageReconciliationHistory',
    components: {LayoutReconciliationStart},
    /**
     * Данные комопнента
     * @property {Array} actionList - список кнопок с действиями
     */
    data: () => ({
        actionList: [
            {
                text: 'Назад',
                form: 'oval',
                emitName: 'onBack'
            },
        ],
    }),
}
</script>
